




































































import ValidationMessages from '@/components/ValidationMessages.vue'
import { Vue, Component } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FORGOT_PASSWORD } from '@/store/auth'
import { PRODUCT_NAME, CONTACT_EMAIL } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Forgot password',
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationMessages,
  },
})
export default class ForgotPassword extends Vue {
  PRODUCT_NAME = PRODUCT_NAME
  CONTACT_EMAIL = CONTACT_EMAIL

  loading = false
  email = ''
  sent = 0
  resendErrors: string[] = []

  get emailSent() {
    return this.sent > 0
  }

  async beforeMount() {
    this.email = Array.isArray(this.$route.query.email)
      ? ''
      : this.$route.query.email ?? ''
    if (this.email) this.resend()
  }

  async resend() {
    this.loading = true
    this.resendErrors = []
    await this.$store
      .dispatch(FORGOT_PASSWORD, this.email)
      .then(() => {
        this.sent += 1
      })
      .catch(err => {
        if (err.code === 'InvalidParameterException')
          this.$router.replace({
            path: '/unconfirmed',
            query: {
              email: this.email,
            },
          })
        else if (err.code === 'UserNotFoundException') {
          // act as if we sent the email to make it harder to fish accounts
          this.sent += 1
        } else this.resendErrors = ['Unexpected error sending reset link']
      })
    this.loading = false
  }

  tryAgain() {
    this.sent = 0
  }
}
